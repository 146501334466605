export const basicColor = {
  /** 主题色 */
  primary: '#7230ff',
  primary10: '#7230ff1A',
  primary15: '#7230ff26',
  primary30: '#7230ff4d',
  primary50: '#7230ff80',
  primary60: '#AD86FF',
  primaryFont: '#C3F0E8',
  background: '#110F36',
  background50: '#110F3680',
  purple: 'rgba(32, 24, 82, 0.8)',
  /** 渐变主题色 */
  proxybgColor: '#C92619',
  proxyResetLink: '#7230FF',
  border: '#DADADA',
  borderShallow: 'rgba(116,116,116,0.13)',
  brown: '#8F4E00',
  red: 'red',
  white: '#FFFFFF',
  dark: '#000',
  transparent: '#0000',
};

export const linearGradientColor = {
  primaryLinearGradient: ['#110F36', '#110F36'],
  linearGradientBtnColor: ['#7230FF', '#9361FF'],
  proxyLinearGradient: ['#dbeeea', '#dbeeea'],
  proxylightLinear: ['#E51251', '#FF8A00'],
  loginLinearGradient: ['#F9F0FF', '#FFF'],
  rechargeButtonLinearGradient: ['#e51251', '#ff8a00'],
  invitationRecordLinearGradient: ['#FBCBCB', 'rgba(255, 255, 255, 0.00)'],

  //home-menu
  chestLinearGradient: ['#1CFFB61a', '#1CFFB64d'],
  //home-menu
  homemenuPromotion: ['rgba(246, 203, 64, 0.30)', 'rgba(246, 203, 64, 0.10)'],
  //中奖状态 背景渐变色
  wonLinearGradient: ['#FFB72833', '#7230FF00'],
  //未中奖状态 背景渐变色
  noWinLinearGradient: ['#6A67A233', '#7230FF00'],
  //未开奖状态 背景渐变色
  toDrawnLinearGradient: ['#8851FF33', '#7230FF00'],
};

export const fontColor = {
  main: '#242424',
  main70: 'rgba(36,36,36,0.7)',
  second: '#31373D',
  accent: '#5F6975',
  secAccent: '#9FA5AC',

  yellow: '#FFB728',
  winColor: '#F44336',
  //中奖状态 文字颜色
  wonFontColor: '#FFB728',
  //未中奖状态 文字颜色
  noWinFontColor: '#6A67A2FF',
  //未开奖状态 文字颜色
  toDrawnFontColor: '#8851FFFF',
  primary: '#7230ff',
  purple: '#6A67A2',
  purple10: '#6A67A21a',
  red: 'red',
  grey: '#D8E0EA',
  green: '#0BD064',
  white: '#ffffff',
  white60: 'rgba(255,255,255,0.6)',
  white80: 'rgba(255,255,255,0.8)',
  brown: '#9F3131',
  button: '#8F4E00',
};

export const backgroundColor = {
  /** 蓝 */
  main: '#7230ff',

  mainDark: '#1F1853',
  mainShallow: '#6A67A2',
  mediumblue: '#0000CD',

  tabCheck: '#3D2D5F',

  black15: '#00000026',
  black50: '#00000080',
  black60: '#00000099',

  purple80: '#14004080',
  /** 橙 */
  second: '#F15802',
  /** 红 */
  accent: '#F44336',
  /** 绿 */
  secAccent: '#009919',
  grey: '#D8E0EA',
  white: '#ffffff',
  white35: '#ffffff59',
  /** 浅灰色,用于图片加载前的颜色 */
  palegrey: '#E8EBEE',
  lightGrey: '#F5F5F5',

  blue: '#1776FF',
  blueGrey: '#B0AEA0',
  palegreyNext: '#F6F7FA',
  lightblue: '#e7f3f1',
};

export const borderColor = {
  primary: '#7230FF',
  primary50: '#7230FF80',
  main: '#7230FF80',
  red: 'red',
  shallow: 'rgba(116,116,116,0.13)',
  secAccent: '#9FA5AC',
  white: '#ffffff',
  white10: '#ffffff1a',
  white20: '#ffffff33',
  white30: '#ffffff4d',
  grey: '#D8E0EA',
};

export const fontSize = {
  xxs: 8,
  xs: 10,
  s: 12,
  m: 14,
  l: 16,
  l1: 18,
  xl: 20,
  xxl: 22,
  xxxl: 24,
  xxxxl: 26,
  xxxxxl: 28,
};

export const paddingSize = {
  zorro: 0,
  xxxs: 2,
  xxs: 4,
  xs: 6,
  s: 8,
  m: 10,
  l: 12,
  xl: 14,
  xxl: 16,
  xxxl: 18,
};

export const borderRadiusSize = {
  xxs: 2,
  xs: 4,
  s: 6,
  m: 8,
  l: 10,
  xl: 12,
  xxl: 14,
  xxxl: 16,
  xxxxl: 18,
  xxxxxl: 22,
  xxxxxxl: 24,
  full: 9999,
};

export const iconSize = {
  xxs: 10,
  xs: 12,
  s: 16,
  m: 20,
  l: 24,
  xl: 28,
  xxl: 32,
};

export const imageSize = {
  xxs: 24,
  xs: 36,
  s: 48,
  m: 64,
  l: 72,
};

export const invitationBaseColors = {
  signGiftBgColor: basicColor.primary,
  registSuccess: basicColor.primary,
};
